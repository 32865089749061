import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { environment } from '@environment/environment';
import { Chapter } from '@model/chapter';
import { ContentItem } from '@model/content-item';
import { forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  chapters = signal<Chapter[]>(null);

  newsItems = computed(() => this.chapters()
    ?.find(chapter => chapter.news)
    ?.contentItems ?? [] as ContentItem[]);

  private httpClient = inject(HttpClient);
  private baseUrl = `${environment.url}/content`;

  initialize(): Observable<Chapter[]> {
    const chapters = this.chapters();
    const content = this.newsItems();
    if ((chapters && chapters.length) || (content && content.length)) {
      console.log('Initialized ContentService from local storage');

      // Let op, er kunnen natuurlijk updates zijn. Die worden op deze manier gemist!!
      // Cachen van content moet dus slimmer.
      return of(void 0);
    }

    return this.fetchChaptersAndContent();
  }

  fetchBodyForContentItemsWithoutBody(): Observable<ContentItem[]> {
    const items = this.chapters()
      .filter(chapter => !chapter.news)
      .flatMap(chapter => chapter.contentItems)
      .filter(contentItem => !contentItem.body);

    const req = items.map(item => this.fetchContentItemWithBody(item)
      .pipe(
        map(res => new ContentItem(res)),
        tap(res => this.updateContentItemBody(res)),
      ));

    return forkJoin(req);
  }

  fetchChaptersAndContent(): Observable<Chapter[]> {
    const url = `${this.baseUrl}/chapters`;

    return this.httpClient.get<Chapter[]>(url)
      .pipe(
        map(chapters => chapters.filter(chapter => chapter.menuCode === 'main' || chapter.news === true)),
        map(chapters => chapters.map(chapter => new Chapter(chapter))),
        tap(chapters => console.log('fetchedChapters', chapters)),
        switchMap(chapters => this.fetchAllChapterItems(chapters)),
        tap(chapters => this.chapters.set(chapters)),
      );
  }

  fetchContentItem(contentItem: ContentItem): Observable<ContentItem> {
    const url = `${this.baseUrl}/items/${contentItem.uuid}`;

    return this.httpClient.get<ContentItem>(url)
      .pipe(
        map(item => {
          item.isNews = contentItem.isNews;

          return new ContentItem(item);
        }),
      );
  }

  fetchContentItemWithBody(contentItem: ContentItem): Observable<ContentItem> {

    return this.fetchContentItem(contentItem)
      .pipe(
        map(res => new ContentItem(res)),
        tap(res => contentItem.body = res.body),
        tap(_ => this.updateContentItemBody(contentItem)),
      );
  }

  getChapterByUuid(uuid: string): Chapter {
    return this.chapters().find(chapter => chapter.uuid === uuid);
  }

  getContentItemByUuid(uuid: string): ContentItem {
    return this.chapters()
      .flatMap(chapter => chapter.contentItems)
      .find(contentItem => contentItem.uuid === uuid);
  }

  fetchAndUpdateChapterItems(chapterName: string, includeBody: boolean): Observable<ContentItem[]> {
    const chapters = this.chapters();
    const chapter = chapters.find(c => c.name === chapterName);

    return this.fetchChapterItems(chapter, includeBody)
      .pipe(
        tap(() => this.chapters.set([...chapters])),
      );
  }

  private updateContentItemBody(contentItem: ContentItem): void {
    const chapters = this.chapters();
    const itemToUpdate = chapters
      .filter(chapter => !chapter.news) // Not saving news item bodies
      .filter(chapter => chapter.uuid === contentItem.chapterUuid)
      .flatMap(chapter => chapter.contentItems)
      .find(item => item.uuid === contentItem.uuid);

    if (itemToUpdate) {
      itemToUpdate.body = contentItem.body;

      this.chapters.set([...chapters]);
    }
  }

  private fetchAllChapterItems(chapters: Chapter[]): Observable<Chapter[]> {
    const req = chapters
      .map(chapter => this.fetchChapterItems(chapter, false));

    return forkJoin(req)
      .pipe(
        tap(results => {
          results
            .filter(result => result.length > 0)
            .forEach(result => {
              const chapter = chapters.find(c => c.uuid === result[0].chapterUuid);
              chapter.contentItems = result;
            });
        }),
        map(_ => chapters),
      );
  }

  private fetchChapterItems(chapter: Chapter, includeBody: boolean): Observable<ContentItem[]> {
    const url = `${this.baseUrl}/chapters/${chapter.uuid}/items?includeBody=${includeBody}`;

    return this.httpClient.get<ContentItem[]>(url)
      .pipe(
        map(items => items.map(item => new ContentItem({ ... item, isNews: chapter.news }))),
        tap(items => chapter.contentItems = items),
      );
  }
}
