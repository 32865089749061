export class ContentItem {
  body: string | null;
  chapterUuid: string;
  description: string;
  isNews: boolean;
  lastmod: number | null;
  position: number;
  title: string;
  url: string;
  uuid: string;

  constructor(obj: ContentItem) {
    this.body = obj.body;
    this.chapterUuid = obj.chapterUuid;
    this.description = obj.description;
    this.isNews = obj.isNews;
    this.lastmod = obj.lastmod;
    this.position = obj.position;
    this.title = obj.title;
    this.url = obj.url;
    this.uuid = obj.uuid;
  }
}
