import { ContentItem } from '@model/content-item';

export class Chapter {
  blog?: string;
  chapters?: Chapter[] = [];
  contentItems?: ContentItem[] = [];
  menuCode?: string;
  menuPosition?: number;
  name: string;
  news?: boolean;
  uuid?: string;

  constructor(obj: Chapter) {
    this.blog = obj.blog;
    this.chapters = obj.chapters || [];
    this.contentItems = obj.contentItems || [];
    this.menuCode = obj.menuCode;
    this.menuPosition = obj.menuPosition;
    this.name = this.toKey(obj.name);
    this.news = obj.news || false;
    this.uuid = obj.uuid;
  }

  private toKey(name: string) {
    // Strip quotes and spaces
    return name.toLowerCase().replace(/['\s]/g, '');
  }
}
